/* eslint-disable @typescript-eslint/no-explicit-any */
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import utc from 'dayjs/plugin/utc';
import {
  Tooltip,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Theme,
} from '@mui/material';
import { useCallback, useState } from 'react';
import theme from 'src/theme';
import { array } from 'yup';

dayjs.extend(utc);

type Params = {
  initialSerial: string | undefined;
  initialGroupName: string | undefined;
  setFilteringOptions: (
    afterDate: string | undefined,
    beforeDate: string | undefined,
    serialNumbers: string,
    groupNames: string,
    eventTypes: string | undefined,
  ) => void;
};

function SecurityEventsSearchBar({
  initialSerial,
  initialGroupName,
  setFilteringOptions,
}: Params) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [afterDate, setAfterDate] = useState<dayjs.Dayjs | null>(null);
  const [beforeDate, setBeforeDate] = useState<dayjs.Dayjs | null>(null);
  const [requestedSerialNumbers, setRequestedSerialNumbers] = useState<
    string | undefined
  >(initialSerial);
  const [requestedGroupNames, setRequestedGroupNames] = useState<
    string | undefined
  >(initialGroupName);
  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>([]);
  const eventTypes = [
    'MqttClientConnected',
    'MqttClientDisconnected',
    'GetMigrationBirthCredentialRequest',
    'MigrationEvent',
    'GetBirthCredentialRequest',
    'IssueCertificateRequest',
    'MqttOtherEvent',
  ];

  function getStyles(
    status: string,
    statuses: readonly string[],
    theme: Theme,
  ) {
    return {
      fontWeight:
        statuses.indexOf(status) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleEventTypesChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedEventTypes(
      typeof value === typeof array ? value.split(',') : value,
    );
  };

  const onAfterChange = useCallback((newDate: dayjs.Dayjs | null) => {
    const startDate = dayjs(newDate).isValid() ? dayjs(newDate) : null;
    setAfterDate(startDate);
  }, []);

  const onBeforeChange = useCallback((newDate: dayjs.Dayjs | null) => {
    const endDate = dayjs(newDate).isValid() ? dayjs(newDate) : null;
    setBeforeDate(endDate);
  }, []);

  const joinStrings = <T extends readonly string[]>(arr: T): `${T[number]}` =>
    arr.join(',') as `${T[number]}`;

  return (
    <div style={{ display: 'flex', alignItems: 'baseline' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label="Timestamp after [UTC]"
          value={afterDate}
          defaultValue={null}
          timezone="UTC"
          slotProps={{
            textField: {
              size: 'small',
              style: { marginLeft: '5px' },
            },
            actionBar: {
              actions: ['clear', 'accept'],
            },
            field: {
              onClear: () => onAfterChange(null),
            },
          }}
          minutesStep={1}
          onChange={(newValue) => {
            onAfterChange(newValue);
          }}
        />
        <DateTimePicker
          label="Timestamp before [UTC]"
          value={beforeDate != null ? beforeDate : null}
          timezone="UTC"
          slotProps={{
            textField: {
              size: 'small',
              style: { marginLeft: '5px', marginRight: '5px' },
            },
            actionBar: {
              actions: ['clear', 'accept'],
            },
            field: { onClear: () => onBeforeChange(null) },
          }}
          onChange={(newValue) => {
            onBeforeChange(newValue);
          }}
        />
      </LocalizationProvider>
      AND
      <Tooltip title="Comma separated values">
        <TextField
          label="Group Names"
          placeholder="groupOne, groupTwo, ..."
          sx={{ width: '300px', margin: '0 5px' }}
          defaultValue={initialGroupName}
          onChange={(e) => setRequestedGroupNames(e.target.value)}
        />
      </Tooltip>
      AND
      <Tooltip title="Comma separated values">
        <TextField
          label="Serial Numbers"
          placeholder="serialOne, serialTwo, ..."
          sx={{ width: '300px', margin: '0 5px' }}
          defaultValue={initialSerial}
          onChange={(e) => setRequestedSerialNumbers(e.target.value)}
        />
      </Tooltip>
      AND
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="status-label" sx={{ marginTop: '-7px' }}>
          Event Types
        </InputLabel>
        <Select
          labelId="status-label"
          size="small"
          multiple
          value={selectedEventTypes}
          onChange={handleEventTypesChange}
          input={<OutlinedInput label="Event Types" />}
          MenuProps={MenuProps}
        >
          {eventTypes.map((eventType) => (
            <MenuItem
              key={eventType}
              value={eventType}
              style={getStyles(eventType, eventTypes, theme)}
            >
              {eventType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <IconButton
          onClick={() =>
            setFilteringOptions(
              afterDate?.toString(),
              beforeDate?.toString(),
              requestedSerialNumbers ?? '',
              requestedGroupNames ?? '',
              selectedEventTypes.length > 0
                ? joinStrings(selectedEventTypes)
                : undefined,
            )
          }
        >
          <CheckOutlinedIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default SecurityEventsSearchBar;
