export const ROOT = '/*';
export const DASHBOARD = '/dashboard';
export const COMMAND_DIAGNOSTICS = '/command-diagnostics';
export const ADMIN_PANEL = '/admin-panel';
export const MIGRATION_DASHBOARD = '/migration-dashboard';
export const SECURITY_LOG = '/security-log';
export const MIGRATION_GROUP = '/migration-dashboard/groups/:groupName';
export const DEVICE_DETAILS = '/device/:id';
export const SETTINGS = '/settings';
export const UNAUTHORIZED = '/unauthorized';
