/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
import PageCard from 'src/components/PageCard';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import { Box, IconButton, Modal, Tooltip, Typography } from '@mui/material';

import {
  useGetMigrationGroupQuery,
  useGetMigrationSummaryQuery,
  useStartMigrationMutation,
} from 'src/backend';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageTitle from 'src/components/PageTitle';
import MigrationProgress from 'src/components/MigrationProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Loader from 'src/components/Loader';
import LoadingIconButton from 'src/components/LoadingIconButton';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import dayjs from 'dayjs';
import ScreenLockLandscapeOutlinedIcon from '@mui/icons-material/ScreenLockLandscapeOutlined';
import { SECURITY_LOG } from 'src/router';
import MigrationGroupEdit from './MigrationGroupEdit';
import MigrationGroupReadOnly from './MigrationGroupReadOnly';

type RouteParams = {
  groupName: string;
};

function MigrationGroup() {
  const { groupName } = useParams<RouteParams>();
  const getMigrationGroup = useGetMigrationGroupQuery(groupName);
  const startMigration = useStartMigrationMutation();
  const getMigrationProgress = useGetMigrationSummaryQuery(groupName);
  const [refetchRequired, setRefetchRequired] = useState(false);
  const [statusRefreshRequired, setStatusRefreshRequired] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const statusInfo: { [key: string]: string } = {
    Notassigned: 'devices not assigned to any migration group',
    Pending: 'device assigned to group, waiting for migration start',
    Preparingota: 'over-the-air is being prepared',
    Waitingfordevice: 'ota sent, waiting for device update',
    Successful: 'device successfuly migrated',
    Failed: 'device migration failed',
  };

  const navigate = useNavigate();

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const cardTitle = `${groupName} | Status:  ${getMigrationGroup.data?.data.status}`;

  const startGroupMigration = useCallback(() => {
    startMigration[0](groupName)
      .unwrap()
      .then(() => getMigrationGroup.refetch())
      .catch();
  }, [getMigrationGroup, groupName, startMigration]);

  useEffect(() => {
    if (statusRefreshRequired) {
      getMigrationProgress.refetch();
    }
  }, [getMigrationProgress, statusRefreshRequired]);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      getMigrationGroup.refetch();
      getMigrationProgress.refetch();
    }
  }, [refetchRequired, getMigrationProgress, getMigrationGroup]);

  return (
    <>
      <PageTitle />
      <PageCard
        title={cardTitle}
        icon={<TableRowsOutlinedIcon />}
        contentSx={{ display: 'block' }}
        sx={{
          '& .MuiCardHeader-action': {
            width: '80em',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: '0',
          },
        }}
        action={
          <>
            <div style={{ width: '95em', paddingTop: '10px', display: 'flex' }}>
              {Object.entries(getMigrationProgress.data?.data || {}).map(
                ([status, count], _index) => (
                  <MigrationProgress
                    key={status}
                    status={status}
                    count={count}
                    showTooltip
                  />
                ),
              )}
            </div>
            {getMigrationGroup.data?.data.status !== 'pending' ? (
              <IconButton
                onClick={() => navigate(SECURITY_LOG, { state: { groupName } })}
              >
                <Tooltip title="Security log">
                  <ScreenLockLandscapeOutlinedIcon fontSize="large" />
                </Tooltip>
              </IconButton>
            ) : (
              ''
            )}
            <IconButton onClick={() => setShowInfoModal(true)}>
              <Tooltip title="Migration statuses info">
                <InfoOutlinedIcon fontSize="large" />
              </Tooltip>
            </IconButton>
            <LoadingIconButton
              loading={
                getMigrationGroup.isLoading || getMigrationGroup.isFetching
              }
              onClick={() => setRefetchRequired(true)}
            >
              <Tooltip title="Refresh">
                <CachedOutlinedIcon fontSize="large" />
              </Tooltip>
            </LoadingIconButton>
            <IconButton
              sx={{
                visibility:
                  getMigrationGroup.data?.data.status === 'pending'
                    ? 'visible'
                    : 'hidden',
              }}
              onClick={() => startGroupMigration()}
            >
              <Tooltip title="Start migration">
                <PlayArrowOutlinedIcon fontSize="large" />
              </Tooltip>
            </IconButton>
            <Modal
              open={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Migration statuses info
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {Object.entries(statusInfo).map(
                    ([status, description], _index) => (
                      <div
                        key={crypto.randomUUID()}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          marginBottom: '10px',
                        }}
                      >
                        <MigrationProgress
                          key={status}
                          status={status}
                          showTooltip={false}
                        />
                        <span>
                          {status} - {description}
                        </span>
                      </div>
                    ),
                  )}
                </Typography>
              </Box>
            </Modal>
          </>
        }
      >
        {getMigrationGroup.isLoading ||
        getMigrationGroup.isFetching ||
        startMigration[1].isLoading ? (
          <Loader />
        ) : getMigrationGroup.data?.data.status === 'pending' ? (
          <MigrationGroupEdit
            groupName={groupName!}
            shouldRefresh={refetchRequired}
            fetchMigrationProgress={() => getMigrationProgress.refetch()}
          />
        ) : (
          <>
            <h3
              style={{
                margin: 0,
                visibility: getMigrationGroup.data?.data.expiresOn
                  ? 'visible'
                  : 'hidden',
              }}
            >
              Expires on:{' '}
              {dayjs
                .utc(getMigrationGroup.data?.data.expiresOn)
                .format('MM-DD-YYYY HH:mm A')}{' '}
              [UTC]
            </h3>
            <MigrationGroupReadOnly
              refreshGroup={() => setRefetchRequired(true)}
              refreshStatus={() => setStatusRefreshRequired(true)}
              shouldRefresh={refetchRequired}
              groupName={groupName!}
            />
          </>
        )}
      </PageCard>
    </>
  );
}

export default MigrationGroup;
