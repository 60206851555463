import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import { DEVICE_DETAILS } from 'src/router';
import { formatDateTime, generatePath } from 'src/utils';
import PageCard from 'src/components/PageCard';
import { DeviceEntry, useListDevicesQuery } from 'src/backend';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import OnOff from 'src/components/OnOff';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';

const columns: GridColDef<DeviceEntry>[] = [
  {
    field: 'id',
    headerName: 'Twin ID',
    flex: 3,
  },
  {
    field: 'serialNumber',
    headerName: 'Serial',
    flex: 1,
  },
  {
    field: 'customerName',
    headerName: 'Customer',
    flex: 1,
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
  },
  {
    field: 'country',
    headerName: 'Country',
    flex: 1,
  },
  {
    field: 'technician',
    headerName: 'Technician',
    flex: 1,
  },
  {
    field: 'isConnected',
    headerName: 'Connected',
    renderCell: (p) => (
      <OnOff value={p.value} onText="Online" offText="Offline" width="10rem" />
    ),
    flex: 1,
  },
  {
    field: 'lastShotTimestamp',
    headerName: 'Last shot',
    flex: 2,
    valueFormatter: (v) => formatDateTime(v.value),
  },
];

declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides {
    onSearch: (searchText: string | undefined) => void;
    onFilterOnline: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }
}

function CustomToolbar(props: {
  onSearch: (searchText: string | undefined) => void;
  onFilterOnline: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  const { onSearch, onFilterOnline } = props;
  const [searchText, setSearchText] = useState('');
  return (
    <Stack direction="row-reverse">
      <IconButton
        onClick={() => onSearch(searchText === '' ? undefined : searchText)}
      >
        <Search />
      </IconButton>
      <TextField
        name="search"
        label="Search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSearch(searchText === '' ? undefined : searchText);
          }
        }}
      />
      <FormControlLabel
        control={<Checkbox onChange={onFilterOnline} />}
        label="Only online devices"
      />
    </Stack>
  );
}

function DashboardDevices() {
  const navigate = useNavigate();

  const mapPageToCursor = useRef<Map<number, string | undefined>>(
    new Map<number, string>(),
  );
  const filter = useRef<string | undefined>(undefined);

  const [pageState, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const [rowCountState, setRowCountState] = useState({
    rowCount: 10,
    totalRowCount: -1,
    topPage: -1,
    isFinal: false,
  });

  const [onlineOnly, setOnlineOnly] = useState(false);
  const cursor = mapPageToCursor.current.get(pageState.page);
  const { data, isLoading, isFetching, refetch } = useListDevicesQuery({
    cursor,
    filter: filter.current,
    onlineOnly,
  });

  const [refetchRequired, setRefetchRequired] = useState(false);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  useEffect(() => {
    if (!isLoading && data?.cursor) {
      mapPageToCursor.current.set(pageState.page + 1, data?.cursor);
    }
  }, [data?.cursor, isLoading, pageState.page]);

  const onPaginationModelChange = (m: GridPaginationModel) => {
    if (m.page !== pageState.page) {
      setPaginationModel({
        page: m.page,
        pageSize: pageState.pageSize,
      });
    }
  };

  useEffect(() => {
    if (
      !rowCountState.isFinal &&
      !isLoading &&
      !isFetching &&
      pageState.page >= rowCountState.topPage
    ) {
      const previousPagesCount = pageState.page * pageState.pageSize;
      const currentPageCount = data?.data.length ?? 0;
      const nextPage = data?.cursor ? 1 : 0;
      const rowCount = previousPagesCount + currentPageCount + nextPage;
      const totalRowCount = nextPage ? -1 : rowCount;
      setRowCountState({
        isFinal: !nextPage,
        rowCount,
        totalRowCount,
        topPage: pageState.page,
      });
    }
  }, [
    rowCountState.isFinal,
    rowCountState.topPage,
    isLoading,
    isFetching,
    pageState.page,
    pageState.pageSize,
    data?.data.length,
    data?.cursor,
  ]);

  const resetPaging = () => {
    mapPageToCursor.current = new Map<number, string | undefined>();
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setRowCountState({
      rowCount: 10,
      totalRowCount: -1,
      topPage: -1,
      isFinal: false,
    });
  };

  const onSearch = (searchText: string | undefined) => {
    if (searchText !== filter.current) {
      filter.current = searchText;

      resetPaging();
    }
  };

  const onFilterOnline = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlineOnly(event.target.checked);
    resetPaging();
  };

  return (
    <PageCard
      title="Device List"
      icon={<TableRowsOutlinedIcon />}
      sx={{
        '& .MuiCardHeader-root': {
          height: '80px',
        },
      }}
      contentSx={{ display: 'block' }}
      action={
        <LoadingIconButton
          loading={isLoading || isFetching}
          onClick={() => setRefetchRequired(true)}
        >
          <Tooltip title="Refresh">
            <CachedOutlinedIcon fontSize="large" />
          </Tooltip>
        </LoadingIconButton>
      }
    >
      <DataGrid
        classes={{ row: 'selectable' }}
        getRowClassName={(params) => {
          if (!params.row.alarmStatus) return '';
          if (params.row.alarmStatus === 'Open') return 'open-alerts';

          return 'completed-alerts';
        }}
        columns={columns}
        rows={data?.data ?? []}
        loading={isFetching}
        onRowClick={({ id }) => navigate(generatePath(DEVICE_DETAILS, { id }))}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            onSearch,
            onFilterOnline,
          },
          pagination: { count: rowCountState.totalRowCount },
        }}
        sx={{
          '& .completed-alerts': {
            border: 2,
            borderRight: 0,
            borderTop: 0,
            borderBottom: 0,
            borderColor: 'green',
          },
          '& .open-alerts': {
            border: 2,
            borderRight: 0,
            borderTop: 0,
            borderBottom: 0,
            borderColor: 'red',
          },
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            display: 'none',
          },
          '& .MuiDataGrid-overlay': {
            height: '100%',
          },
          '& .MuiTablePagination-displayedRows': {
            display: 'none',
          },
        }}
        rowCount={rowCountState.rowCount}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        paginationModel={pageState}
        pageSizeOptions={[10]}
      />
    </PageCard>
  );
}

export default DashboardDevices;
