import { Grid, Tooltip } from '@mui/material';
import { DataGrid, GridToolbar, GridColDef } from '@mui/x-data-grid';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';
import {
  GroupDevice,
  MigrationDevice,
  useAssignGroupDeviceMutation,
  useListAllMigrationDevicesQuery,
  useListGroupDevicesQuery,
  useRemoveDeviceMutation,
} from 'src/backend';
import { useCallback, useEffect, useState } from 'react';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import OnOff from 'src/components/OnOff';

type Params = {
  groupName: string;
  shouldRefresh: boolean;
  fetchMigrationProgress: () => void;
};

function MigrationGroupEdit({
  groupName,
  shouldRefresh,
  fetchMigrationProgress,
}: Params) {
  function getRowId(row: GroupDevice) {
    return row.serialNumber;
  }

  const [refetchRequired, setRefetchRequired] = useState(false);
  const removeDevice = useRemoveDeviceMutation();
  const assignGroupDevice = useAssignGroupDeviceMutation();
  const { data, isLoading, isFetching, refetch } =
    useListGroupDevicesQuery(groupName);
  const listAllDevices = useListAllMigrationDevicesQuery({});

  useEffect(() => {
    if (shouldRefresh) {
      setRefetchRequired(true);
    }
  }, [shouldRefresh]);

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
      fetchMigrationProgress();
      listAllDevices.refetch();
    }
  }, [refetchRequired, refetch, fetchMigrationProgress, listAllDevices]);

  const addDevice = useCallback(
    (groupName: string | undefined, serialNumber: string) => {
      assignGroupDevice[0]({ groupName, serialNumber })
        .unwrap()
        .then(() => setRefetchRequired(true))
        .catch();
    },
    [assignGroupDevice],
  );

  const removeDeviceFromGroup = useCallback(
    (serialNumber: string) => {
      removeDevice[0](serialNumber)
        .unwrap()
        .then(() => setRefetchRequired(true))
        .catch();
    },
    [removeDevice],
  );

  const deviceColumns: GridColDef<GroupDevice>[] = [
    {
      field: 'serialNumber',
      headerName: 'Serial',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      renderCell: (params) => (
        <LoadingIconButton
          onClick={() => removeDeviceFromGroup(params.row.serialNumber)}
          loading={removeDevice[1].isLoading}
        >
          <Tooltip title="Remove device">
            <RemoveOutlinedIcon />
          </Tooltip>
        </LoadingIconButton>
      ),
    },
  ];

  const searchColumns: GridColDef<MigrationDevice>[] = [
    {
      field: 'serialNumber',
      headerName: 'Serial',
      flex: 1,
    },
    {
      field: 'customerName',
      headerName: 'Customer',
      flex: 1,
    },
    {
      field: 'isMigrated',
      headerName: 'Migration status',
      flex: 1,
      renderCell: (p) => {
        const isMigrated = p.value ? true : undefined;
        return (
          <OnOff
            value={isMigrated}
            onText="Successful"
            unknownText="Pending"
            width="10rem"
          />
        );
      },
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <LoadingIconButton
          onClick={() => addDevice(groupName, params.row.serialNumber)}
          loading={assignGroupDevice[1].isLoading}
        >
          <Tooltip title="Add device">
            <AddOutlinedIcon />
          </Tooltip>
        </LoadingIconButton>
      ),
    },
  ];

  return (
    <div style={{ display: 'flex', height: '100%', paddingBottom: '4em' }}>
      <Grid item xs={6} sx={{ width: '40%', paddingTop: '41px' }}>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          getRowId={(row) => getRowId(row)}
          columns={deviceColumns}
          rows={data?.data ?? []}
          loading={isLoading || isFetching}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-overlay': {
              height: '100%',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-main': {
              border: 1,
              borderColor: 'black',
            },
          }}
          pageSizeOptions={[10]}
          hideFooterSelectedRowCount
        />
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Add devices to the group">
          <KeyboardDoubleArrowLeftOutlinedIcon fontSize="large" />
        </Tooltip>
      </div>
      <Grid item xs={6} sx={{ width: '55%' }}>
        <DataGrid
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          columns={searchColumns}
          rows={listAllDevices.data?.data ?? []}
          loading={listAllDevices.isLoading || listAllDevices.isFetching}
          getRowClassName={() => 'with-border'}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-overlay': {
              height: '100%',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-main': {
              border: 1,
              borderColor: 'black',
            },
          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          pageSizeOptions={[10]}
          paginationMode="client"
        />
      </Grid>
    </div>
  );
}

export default MigrationGroupEdit;
