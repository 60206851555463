/* eslint-disable @typescript-eslint/no-explicit-any */
import { HighlightOffOutlined } from '@mui/icons-material';
import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { PortalRole, RoleAssignment } from 'src/backend/types';
import { useCreateRoleAssignmentMutation } from 'src/backend';
import DataField from 'src/components/DataField/DataField';
import LoadingButton from 'src/components/LoadingButton';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import { IDynamicPerson, PeoplePicker } from '@microsoft/mgt-react';
import './CreateRoleAssignmentForm.css';

dayjs.extend(utc);

type Params = {
  isOpen: boolean;
  closeForm: (roleAssignment: RoleAssignment | undefined) => void;
};

function CreateRoleAssignmentForm({ isOpen, closeForm }: Params) {
  const [submitDisabled, setSubmitDisabled] = useState(true);
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const host = document.querySelector('mgt-people-picker');
        if (host && host.shadowRoot) {
          const element = host.shadowRoot.querySelector('div > mgt-flyout');
          if (element) {
            const style = document.createElement('style');
            style.innerHTML =
              'fluent-text-field:after { border-bottom: none; }';

            element.appendChild(style);
          }
        }
      }, 50);
    }
  });

  const [formData, setFormData] = useState<RoleAssignment>({
    userId: '',
    name: '',
    principalName: '',
    role: PortalRole[PortalRole.Read],
    expiresOn: undefined,
  } as RoleAssignment);

  const [createRoleAssignment, { isLoading }] =
    useCreateRoleAssignmentMutation();

  const setExpiresOn = (value: dayjs.Dayjs | null) => {
    setFormData({
      ...formData,
      expiresOn: value?.toISOString() ?? undefined,
    });
  };

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    createRoleAssignment({ roleAssignment: formData })
      .unwrap()
      .then((roleAssignment) => closeForm(roleAssignment))
      .catch(() => closeForm(undefined))
      .finally(() =>
        setFormData({
          userId: '',
          name: '',
          principalName: '',
          role: PortalRole[PortalRole.Read],
          expiresOn: undefined,
        } as RoleAssignment),
      );
  };

  function userSelectionChanged(e: CustomEvent<IDynamicPerson[]>) {
    const person = e.detail[0] as microsoftgraph.Person;
    if (person && person.id) {
      setFormData({
        ...formData,
        userId: person.id,
        name: person.displayName ?? '',
        principalName: person.userPrincipalName ?? '',
      });
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => closeForm(undefined)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        Create Role Assignment
        <IconButton
          onClick={() => closeForm(undefined)}
          style={{ float: 'right' }}
        >
          <HighlightOffOutlined />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid item columnSpacing={2}>
            <Grid item xs="auto" rowGap={1}>
              <DataField
                label="User ID *"
                labelPadding="10px 0 0 0"
                value={
                  <PeoplePicker
                    className="people-picker"
                    aria-required
                    selectionMode="single"
                    type="person"
                    userType="user"
                    selectionChanged={(e) => userSelectionChanged(e)}
                  />
                }
              />
              <DataField
                label="Role"
                labelPadding="10px 0 0 0"
                value={
                  <Select
                    sx={{
                      marginBottom: '10px',
                      width: '250px',
                      height: '40px',
                    }}
                    name="role"
                    type="string"
                    value={formData.role}
                    onChange={(e) => handleInputChange(e)}
                  >
                    <MenuItem value={PortalRole[PortalRole.Read]}>
                      Read
                    </MenuItem>
                    <MenuItem value={PortalRole[PortalRole.Write]}>
                      Write
                    </MenuItem>
                    <MenuItem value={PortalRole[PortalRole.Admin]}>
                      Admin
                    </MenuItem>
                    <MenuItem value={PortalRole[PortalRole.Migrator]}>
                      Migrator
                    </MenuItem>
                    <MenuItem value={PortalRole[PortalRole.SecurityLogViewer]}>
                      Security Log Viewer
                    </MenuItem>
                  </Select>
                }
              />
              <DataField
                label="Expires on"
                labelPadding="10px 0 0 0"
                value={
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      slotProps={{
                        actionBar: {
                          actions: ['clear', 'accept'],
                        },
                      }}
                      sx={{ width: '250px' }}
                      name="expiresOn"
                      value={
                        formData.expiresOn
                          ? dayjs(formData.expiresOn)
                          : undefined
                      }
                      onAccept={(val) => setExpiresOn(val)}
                    />
                  </LocalizationProvider>
                }
              />
              <Container style={{ marginTop: '5em', alignItems: 'end' }}>
                <LoadingButton
                  loading={isLoading}
                  variant="outlined"
                  type="submit"
                  disabled={submitDisabled}
                >
                  Submit
                </LoadingButton>
              </Container>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateRoleAssignmentForm;
