import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SxProps, Theme, Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CloudCircleOutlinedIcon from '@mui/icons-material/CloudCircleOutlined';

type Params = {
  status: string;
  showTooltip: boolean;
  count?: number;
};

type Props = {
  status: string;
  icon: JSX.Element;
  showTooltip: boolean;
  count?: number;
};

function StatusIcon({ status, icon, count, showTooltip }: Props) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: '15px',
      }}
    >
      {showTooltip ? <Tooltip title={status}>{icon}</Tooltip> : icon}
      <span>{count}</span>
    </div>
  );
}

function prepareMigrationSummaryIcon(
  status: string,
  showTooltip: boolean,
  count?: number,
): JSX.Element {
  const sx: SxProps<Theme> = { marginRight: '5px' };
  if (status === 'Notassigned') {
    const icon = <RadioButtonUncheckedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Pending') {
    const icon = <RadioButtonCheckedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Preparingota') {
    const icon = <CloudCircleOutlinedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Waitingfordevice') {
    const icon = <BuildCircleOutlinedIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  if (status === 'Successful') {
    const icon = <CheckCircleIcon sx={sx} />;
    return (
      <StatusIcon
        status={status}
        icon={icon}
        count={count}
        showTooltip={showTooltip}
      />
    );
  }
  const icon = <ErrorOutlineOutlinedIcon sx={sx} />;
  return (
    <StatusIcon
      status={status}
      icon={icon}
      count={count}
      showTooltip={showTooltip}
    />
  );
}

export default function MigrationProgress({
  status,
  showTooltip,
  count,
}: Params) {
  const capitalizedStatus =
    status.slice(0, 1).toUpperCase() + status.slice(1, status.length);
  return prepareMigrationSummaryIcon(capitalizedStatus, showTooltip, count);
}
