import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/helpers';
import { generatePath } from 'react-router-dom';
import {
  CommandResult,
  CommandsSummary,
  Cursor,
  DashboardStatus,
  DeviceAlarm,
  DeviceAnalytics,
  DeviceCustomAnalytics,
  DeviceDetails,
  DeviceEntry,
  GroupDevice,
  MigrationDevice,
  MigrationGroup,
  MigrationSummary,
  RoleAssignment,
  SecurityEvent,
  StatusCount,
  UserProfile,
} from './types';

export const backendApi = createApi({
  reducerPath: 'backendApi',
  baseQuery: baseQuery(),
  endpoints: ({ query, mutation }) => ({
    listDevices: query<
      Cursor<DeviceEntry[]>,
      {
        cursor: string | undefined;
        filter: string | undefined;
        onlineOnly: boolean;
      }
    >({
      query: ({ cursor, filter, onlineOnly }) => ({
        url: '/device',
        method: 'GET',
        queryParams: { cursor, filter, onlineOnly },
      }),
      transformResponse: (res: {
        data: DeviceEntry[];
        cursor?: { next?: string };
      }) => ({ data: res.data, cursor: res.cursor?.next }),
    }),
    getDeviceDetails: query<DeviceDetails, string>({
      query: (id) => ({
        url: generatePath('/device/:id', { id }),
        method: 'GET',
      }),
      transformResponse: (res: { data: DeviceDetails }) => res.data,
    }),
    getDeviceAlarms: query<DeviceAlarm[], string>({
      query: (id) => ({
        url: generatePath('/device/:id/alarms', { id }),
        method: 'GET',
      }),
      transformResponse: (res: { data: DeviceAlarm[] }) => res.data,
    }),
    getDashboardStatus: query<DashboardStatus, string>({
      query: (since) => ({
        url: generatePath('/dashboardStatus'),
        queryParams: { since },
        method: 'GET',
      }),
      transformResponse: (res: { data: DashboardStatus }) => res.data,
    }),
    getCommands: query<
      CommandsSummary,
      {
        cursor: string | undefined;
        registeredAfter: string | undefined;
        registeredBefore: string | undefined;
        serialNumbers: string | undefined;
        statuses: string | undefined;
      }
    >({
      query: ({
        cursor,
        registeredAfter,
        registeredBefore,
        serialNumbers,
        statuses,
      }) => ({
        url: '/commands',
        method: 'GET',
        queryParams: {
          cursor,
          registeredAfter,
          registeredBefore,
          serialNumbers,
          statuses,
        },
      }),
      transformResponse: (res: {
        data: CommandResult[];
        cursor?: { next?: string };
        statusesCount: StatusCount[];
      }) => ({
        data: res.data,
        cursor: res.cursor?.next,
        statusesCount: res.statusesCount,
      }),
    }),
    listRoleAssignments: query({
      query: () => ({
        url: '/roleAssignments',
        method: 'GET',
      }),
      transformResponse: (res: { data: RoleAssignment[] }) => ({
        data: res.data,
      }),
    }),
    getUserProfile: query({
      query: () => ({
        url: '/userProfile/me',
        method: 'GET',
      }),
      transformResponse: (res: { data: UserProfile }) => ({
        data: res.data,
      }),
    }),
    listMigrationGroups: query({
      query: () => ({
        url: '/migration/groups/list',
        method: 'GET',
      }),
      transformResponse: (res: { data: MigrationGroup[] }) => ({
        data: res.data,
      }),
    }),
    getMigrationGroup: query({
      query: (groupName) => ({
        url: generatePath('/migration/groups/:groupName', { groupName }),
        method: 'GET',
      }),
      transformResponse: (res: { data: MigrationGroup }) => ({
        data: res.data,
      }),
    }),
    listAllMigrationDevices: query({
      query: () => ({
        url: '/migration/devices/list',
        method: 'GET',
      }),
      transformResponse: (res: { data: MigrationDevice[] }) => ({
        data: res.data,
      }),
    }),
    listGroupDevices: query({
      query: (groupName) => ({
        url: generatePath('/migration/groups/:groupName/devices', {
          groupName,
        }),
        method: 'GET',
      }),
      transformResponse: (res: { data: GroupDevice[] }) => ({
        data: res.data,
      }),
    }),
    getMigrationSummary: query({
      query: (byGroup: string | undefined) => ({
        url: '/migration/summary',
        method: 'GET',
        queryParams: { byGroup },
      }),
      transformResponse: (res: { data: MigrationSummary }) => ({
        data: res.data.statusesCount,
      }),
    }),
    getSecurityLog: query({
      query: ({
        startDate,
        endDate,
        serials,
        groupNames,
        eventTypes,
        take,
        page,
      }) => ({
        url: '/device/securityLog',
        method: 'GET',
        queryParams: {
          startDate,
          endDate,
          serials,
          groupNames,
          eventTypes,
          take,
          page,
        },
      }),
      transformResponse: (res: { data: SecurityEvent[] }) => ({
        data: res.data,
      }),
    }),
    refreshConfiguration: mutation({
      query: (id) => ({
        url: generatePath('/device/:id/refreshConfiguration', { id }),
        method: 'POST',
      }),
      transformResponse: (res: { data: string }) => res.data,
    }),
    updateConfiguration: mutation({
      query: ({ id, configurationData }) => ({
        url: generatePath('/device/:id/updateConfiguration', { id }),
        method: 'POST',
        body: configurationData,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: string }) => res.data,
    }),
    getAnalytics: mutation({
      query: ({ id, analyticsDataRequest }) => ({
        url: generatePath('/device/:id/analyticsData', { id }),
        method: 'POST',
        body: analyticsDataRequest,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: DeviceAnalytics }) => res.data,
    }),
    getCustomAnalytics: mutation({
      query: ({ id, customAnalyticsDataRequest }) => ({
        url: generatePath('/device/:id/customAnalyticsData', { id }),
        method: 'POST',
        body: customAnalyticsDataRequest,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: DeviceCustomAnalytics }) => res.data,
    }),
    createRoleAssignment: mutation({
      query: ({ roleAssignment }) => ({
        url: '/roleAssignments',
        method: 'POST',
        body: roleAssignment,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
    updateRoleAssignmentExpiration: mutation({
      query: ({ userId, role, roleAssignmentExpiration }) => ({
        url: generatePath('/roleAssignments/:userId/roles/:role', {
          userId,
          role,
        }),
        method: 'PUT',
        body: roleAssignmentExpiration,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
    deleteRoleAssignment: mutation({
      query: ({ userId, role }) => ({
        url: generatePath('/roleAssignments/:userId/roles/:role', {
          userId,
          role,
        }),
        method: 'DELETE',
      }),
      transformResponse: (res: { data: RoleAssignment }) => res.data,
    }),
    createMigrationGroup: mutation({
      query: (createMigrationGroupRequest) => ({
        url: '/migration/groups',
        method: 'POST',
        body: createMigrationGroupRequest,
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: MigrationGroup }) => res.data,
    }),
    deleteMigrationGroup: mutation({
      query: (groupName) => ({
        url: generatePath('/migration/groups/:groupName', {
          groupName,
        }),
        method: 'DELETE',
      }),
    }),
    unassignFailedDevice: mutation({
      query: (serialNumber) => ({
        url: generatePath('/migration/devices/:serialNumber/unassign', {
          serialNumber,
        }),
        method: 'POST',
      }),
    }),
    markDeviceSuccessful: mutation({
      query: (serialNumber) => ({
        url: generatePath('/migration/devices/:serialNumber/markSuccessful', {
          serialNumber,
        }),
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: GroupDevice }) => res.data,
    }),
    assignGroupDevice: mutation({
      query: ({ groupName, serialNumber }) => ({
        url: generatePath(
          '/migration/groups/:groupName/devices/:serialNumber',
          {
            groupName,
            serialNumber,
          },
        ),
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      }),
      transformResponse: (res: { data: GroupDevice }) => res.data,
    }),
    removeDevice: mutation({
      query: (serialNumber) => ({
        url: generatePath('/migration/devices/:serialNumber', {
          serialNumber,
        }),
        method: 'DELETE',
      }),
    }),
    startMigration: mutation({
      query: (groupName) => ({
        url: generatePath('/migration/groups/:groupName/startMigration', {
          groupName,
        }),
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useListDevicesQuery,
  useGetDeviceDetailsQuery,
  useGetDeviceAlarmsQuery,
  useGetDashboardStatusQuery,
  useGetCommandsQuery,
  useLazyGetUserProfileQuery,
  useListRoleAssignmentsQuery,
  useListMigrationGroupsQuery,
  useListGroupDevicesQuery,
  useGetMigrationGroupQuery,
  useListAllMigrationDevicesQuery,
  useGetMigrationSummaryQuery,
  useGetSecurityLogQuery,
  useRefreshConfigurationMutation,
  useUpdateConfigurationMutation,
  useGetAnalyticsMutation,
  useGetCustomAnalyticsMutation,
  useCreateRoleAssignmentMutation,
  useUpdateRoleAssignmentExpirationMutation,
  useDeleteRoleAssignmentMutation,
  useCreateMigrationGroupMutation,
  useDeleteMigrationGroupMutation,
  useAssignGroupDeviceMutation,
  useRemoveDeviceMutation,
  useStartMigrationMutation,
  useMarkDeviceSuccessfulMutation,
  useUnassignFailedDeviceMutation,
} = backendApi;
