/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import {
  CreateMigrationGroupRequest,
  MigrationGroup,
  useCreateMigrationGroupMutation,
  useDeleteMigrationGroupMutation,
  useGetMigrationSummaryQuery,
  useListMigrationGroupsQuery,
} from 'src/backend';
import PageCard from 'src/components/PageCard';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import LoadingIconButton from 'src/components/LoadingIconButton';
import { useCallback, useEffect, useState } from 'react';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LoadingButton from 'src/components/LoadingButton';
import { generatePath, useNavigate } from 'react-router-dom';
import { MIGRATION_GROUP, SECURITY_LOG } from 'src/router';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import MigrationProgress from 'src/components/MigrationProgress';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import ScreenLockLandscapeOutlinedIcon from '@mui/icons-material/ScreenLockLandscapeOutlined';

function MigrationPanel() {
  const navigate = useNavigate();
  const { data, isLoading, isFetching, refetch } = useListMigrationGroupsQuery(
    {},
  );
  const createMigrationGroup = useCreateMigrationGroupMutation();
  const deleteMigrationGroup = useDeleteMigrationGroupMutation();
  const getMigrationProgress = useGetMigrationSummaryQuery(undefined);
  const [refetchRequired, setRefetchRequired] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [showInfoModal, setShowInfoModal] = useState(false);
  const statusInfo: { [key: string]: string } = {
    Notassigned: 'devices not assigned to any migration group',
    Pending: 'device assigned to group, waiting for migration start',
    Preparingota: 'over-the-air is being prepared',
    Waitingfordevice: 'ota sent, waiting for device update',
    Successful: 'device successfuly migrated',
    Failed: 'device migration failed',
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function getRowId(row: MigrationGroup) {
    return `${row.name}__${row.status}`;
  }

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
      getMigrationProgress.refetch();
    }
  }, [refetchRequired, refetch, getMigrationProgress]);
  const apiRef = useGridApiRef();

  const handleCreate = () => {
    const request = {
      groupName: newGroupName,
    } as CreateMigrationGroupRequest;
    createMigrationGroup[0](request)
      .unwrap()
      .then((migrationGroup) => {
        refetch();
        setShowModal(false);
        navigate(
          generatePath(MIGRATION_GROUP, { groupName: migrationGroup.name }),
        );
      })
      .catch()
      .finally(() => setNewGroupName(''));
  };

  const handleDelete = useCallback(
    (groupName: string) => {
      deleteMigrationGroup[0](groupName)
        .unwrap()
        .then(() => refetch())
        .catch();
    },
    [deleteMigrationGroup, refetch],
  );

  const columns: GridColDef<MigrationGroup>[] = [
    {
      field: 'name',
      headerName: 'Group Name',
      minWidth: 200,
      flex: 0.4,
    },
    {
      field: 'status',
      headerName: 'Migration Status',
      flex: 1,
    },
    {
      field: 'migrationSummary',
      headerName: 'Migration Summary',
      renderCell: (params) => (
        <div style={{ width: '95em', paddingTop: '10px', display: 'flex' }}>
          {Object.entries(params.row.migrationSummary.statusesCount || {}).map(
            ([status, count], _index) => (
              <MigrationProgress
                key={status}
                status={status}
                count={count}
                showTooltip
              />
            ),
          )}
        </div>
      ),
      flex: 1,
    },
    {
      field: 'expiresOn',
      headerName: 'Expiration Date [UTC]',
      valueFormatter: (v) =>
        v.value ? dayjs.utc(v.value).format('MM-DD-YYYY HH:mm A') : '-',
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === 'pending')
          return (
            <LoadingIconButton
              onClick={() => handleDelete(params.row.name)}
              loading={deleteMigrationGroup[1].isLoading}
            >
              <Tooltip title="Delete group">
                <DeleteOutlineOutlinedIcon />
              </Tooltip>
            </LoadingIconButton>
          );
        return (
          <IconButton
            onClick={() =>
              navigate(SECURITY_LOG, {
                state: { groupName: params.row.name },
              })
            }
          >
            <Tooltip title="Security log">
              <ScreenLockLandscapeOutlinedIcon />
            </Tooltip>
          </IconButton>
        );
      },
    },
  ];

  const preventSelectingOnCell = ({ field }: { field: string }, event: any) => {
    if (field === 'action') {
      event.stopPropagation();
    }
  };

  return (
    <>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>Enter group name</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mt: 2 }}
            label="Group name"
            variant="standard"
            value={newGroupName}
            onChange={(e) => {
              setNewGroupName(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={createMigrationGroup[1].isLoading}
            variant="outlined"
            type="submit"
            onClick={handleCreate}
            disabled={newGroupName.length <= 0}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <PageCard
        title="Migration groups"
        icon={<TableRowsOutlinedIcon />}
        sx={{
          '& .MuiCardHeader-root': {
            height: '80px',
          },
          '& .MuiCardHeader-action': {
            width: '80em',
            display: 'flex',
            marginLeft: 'auto',
            marginRight: '0',
          },
        }}
        action={
          <>
            <div style={{ width: '95em', paddingTop: '10px', display: 'flex' }}>
              {Object.entries(getMigrationProgress.data?.data || {}).map(
                ([status, count], _index) => (
                  <MigrationProgress
                    key={status}
                    status={status}
                    count={count}
                    showTooltip
                  />
                ),
              )}
            </div>
            <IconButton onClick={() => setShowInfoModal(true)}>
              <Tooltip title="Migration statuses info">
                <InfoOutlinedIcon fontSize="large" />
              </Tooltip>
            </IconButton>
            <IconButton onClick={() => setShowModal(true)}>
              <Tooltip title="Add migration group">
                <AddCircleOutlineOutlinedIcon />
              </Tooltip>
            </IconButton>
            <LoadingIconButton
              loading={isFetching}
              onClick={() => setRefetchRequired(true)}
            >
              <Tooltip title="Refresh">
                <CachedOutlinedIcon fontSize="large" />
              </Tooltip>
            </LoadingIconButton>
            <Modal
              open={showInfoModal}
              onClose={() => setShowInfoModal(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={modalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Migration statuses info
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {Object.entries(statusInfo).map(
                    ([status, description], _index) => (
                      <div
                        key={crypto.randomUUID()}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                          marginBottom: '10px',
                        }}
                      >
                        <MigrationProgress
                          key={status}
                          status={status}
                          showTooltip={false}
                        />
                        <span>
                          {status} - {description}
                        </span>
                      </div>
                    ),
                  )}
                </Typography>
              </Box>
            </Modal>
          </>
        }
      >
        <DataGrid
          onCellClick={preventSelectingOnCell}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          apiRef={apiRef}
          getRowId={(row) => getRowId(row)}
          onRowClick={(params) =>
            navigate(
              generatePath(MIGRATION_GROUP, { groupName: params.row.name }),
            )
          }
          columns={columns}
          rows={data?.data ?? []}
          loading={isFetching || isLoading}
          sx={{
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              display: 'none',
            },
            '& .MuiDataGrid-overlay': {
              height: '100%',
            },
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          disableRowSelectionOnClick
          paginationMode="client"
        />
      </PageCard>
    </>
  );
}

export default MigrationPanel;
