import { Navigate } from 'react-router-dom';
import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Dashboard from 'src/pages/Dashboard';
import DeviceDetails, {
  routes as deviceDetailsRoutes,
} from 'src/pages/DeviceDetails';
import CommandsDashboard from 'src/pages/CommandsDashboard/CommandsDashboard';
import AdminDashboard from 'src/pages/AdminDashboard/AdminDashboard';
import RoleBased from 'src/components/RoleBased';
import Unauthorized from 'src/pages/Unauthorized';
import { PortalRole } from 'src/backend';
import MigrationDashboard from 'src/pages/MigrationDashboard/MigrationDashboard';
import MoveUpOutlinedIcon from '@mui/icons-material/MoveUpOutlined';
import MigrationGroup from 'src/pages/MigrationGroup/MigrationGroup';
import SecurityLogDashboard from 'src/pages/SecurityLog/SecurityLogDashboard';
import ScreenLockLandscapeOutlinedIcon from '@mui/icons-material/ScreenLockLandscapeOutlined';
import { RouteProps } from './types';
import * as ROUTES from './constants';

export const routes: RouteProps[] = [
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.DASHBOARD} replace />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.COMMAND_DIAGNOSTICS} replace />,
  },
  {
    path: ROUTES.ROOT,
    element: <Navigate to={ROUTES.UNAUTHORIZED} replace />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<Dashboard />}
      />
    ),
    meta: {
      title: 'Dashboard',
      icon: MonitorHeartOutlinedIcon,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.COMMAND_DIAGNOSTICS,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<CommandsDashboard />}
      />
    ),
    meta: {
      title: 'Command Diagnostics',
      icon: AnalyticsOutlinedIcon,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.ADMIN_PANEL,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Admin]}
        component={<AdminDashboard />}
      />
    ),
    meta: {
      title: 'Admin Panel',
      icon: ManageAccountsOutlinedIcon,
      accessRole: PortalRole[PortalRole.Admin],
    },
  },
  {
    path: ROUTES.MIGRATION_DASHBOARD,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Migrator]}
        component={<MigrationDashboard />}
      />
    ),
    meta: {
      title: 'Migration Dashboard',
      icon: MoveUpOutlinedIcon,
      accessRole: PortalRole[PortalRole.Migrator],
    },
  },
  {
    path: ROUTES.SECURITY_LOG,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.SecurityLogViewer]}
        component={<SecurityLogDashboard />}
      />
    ),
    meta: {
      title: 'Security Log',
      icon: ScreenLockLandscapeOutlinedIcon,
      accessRole: PortalRole[PortalRole.SecurityLogViewer],
    },
  },
  {
    path: ROUTES.DEVICE_DETAILS,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Read]}
        component={<DeviceDetails />}
      />
    ),
    children: deviceDetailsRoutes,
    meta: {
      title: 'Device Details',
      navLink: false,
      accessRole: PortalRole[PortalRole.Read],
    },
  },
  {
    path: ROUTES.MIGRATION_GROUP,
    element: (
      <RoleBased
        shouldRedirect
        requiredRole={PortalRole[PortalRole.Migrator]}
        component={<MigrationGroup />}
      />
    ),
    meta: {
      title: 'Group Details',
      navLink: false,
      accessRole: PortalRole[PortalRole.Migrator],
    },
  },
  {
    path: ROUTES.UNAUTHORIZED,
    element: <Unauthorized />,
    meta: {
      title: 'Unauthorized',
      navLink: false,
    },
  },
];
