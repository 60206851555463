/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { useCallback, useEffect } from 'react';
import {
  GroupDevice,
  useListGroupDevicesQuery,
  useMarkDeviceSuccessfulMutation,
  useUnassignFailedDeviceMutation,
} from 'src/backend';
import { useNavigate } from 'react-router-dom';
import { SECURITY_LOG } from 'src/router';
import LoadingIconButton from 'src/components/LoadingIconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import ScreenLockLandscapeOutlinedIcon from '@mui/icons-material/ScreenLockLandscapeOutlined';

type Params = {
  groupName: string;
  shouldRefresh: boolean;
  refreshGroup: () => void;
  refreshStatus: () => void;
};

function MigrationGroupReadOnly({
  groupName,
  shouldRefresh,
  refreshGroup,
  refreshStatus,
}: Params) {
  function getRowId(row: GroupDevice) {
    return row.serialNumber;
  }
  const navigate = useNavigate();
  const apiRef = useGridApiRef();

  const { data, isLoading, isFetching, refetch } =
    useListGroupDevicesQuery(groupName);

  const unassignDevice = useUnassignFailedDeviceMutation();
  const markDeviceSuccessful = useMarkDeviceSuccessfulMutation();

  const performUnassign = useCallback(
    (serialNumber: string) => {
      unassignDevice[0](serialNumber)
        .unwrap()
        .then(() => refreshGroup())
        .catch();
    },
    [refreshGroup, unassignDevice],
  );

  const markSuccessful = useCallback(
    (serialNumber: string) => {
      markDeviceSuccessful[0](serialNumber)
        .unwrap()
        .then((val) => {
          apiRef.current.updateRows([
            { serialNumber: val.serialNumber, status: val.status },
          ]);
        })
        .catch()
        .finally(() => refreshStatus());
    },
    [markDeviceSuccessful, apiRef, refreshStatus],
  );

  useEffect(() => {
    if (shouldRefresh) {
      refetch();
    }
  }, [refetch, shouldRefresh]);

  const deviceColumns: GridColDef<GroupDevice>[] = [
    {
      field: 'serialNumber',
      headerName: 'Serial',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) =>
        params.row.status === 'failed' ? (
          <>
            <LoadingIconButton
              onClick={() => markSuccessful(params.row.serialNumber)}
              loading={
                markDeviceSuccessful[1].isLoading || unassignDevice[1].isLoading
              }
            >
              <Tooltip title="Mark as successful">
                <CheckCircleIcon />
              </Tooltip>
            </LoadingIconButton>
            <LoadingIconButton
              onClick={() => performUnassign(params.row.serialNumber)}
              loading={
                unassignDevice[1].isLoading || markDeviceSuccessful[1].isLoading
              }
            >
              <Tooltip title="Unassign">
                <ErrorOutlineOutlinedIcon />
              </Tooltip>
            </LoadingIconButton>
            <IconButton
              onClick={() =>
                navigate(SECURITY_LOG, {
                  state: { serial: params.row.serialNumber },
                })
              }
            >
              <Tooltip title="Device security log">
                <ScreenLockLandscapeOutlinedIcon />
              </Tooltip>
            </IconButton>
          </>
        ) : (
          <IconButton
            onClick={() =>
              navigate(SECURITY_LOG, {
                state: { serial: params.row.serialNumber },
              })
            }
          >
            <Tooltip title="Device security log">
              <ScreenLockLandscapeOutlinedIcon />
            </Tooltip>
          </IconButton>
        ),
    },
  ];

  return (
    <Grid item xs={6} sx={{ width: '100%', paddingTop: '41px' }}>
      <DataGrid
        apiRef={apiRef}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        getRowId={(row) => getRowId(row)}
        columns={deviceColumns}
        rows={data?.data ?? []}
        loading={isLoading || isFetching}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
            display: 'none',
          },
          '& .MuiDataGrid-overlay': {
            height: '100%',
          },
          '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-main': {
            border: 1,
            borderColor: 'black',
          },
        }}
        pageSizeOptions={[10]}
        hideFooterSelectedRowCount
      />
    </Grid>
  );
}

export default MigrationGroupReadOnly;
