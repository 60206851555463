import Grid from '@mui/material/Grid';
import PageTitle from 'src/components/PageTitle';
import SecurityEvents from './SecurityEvents';

function SecurityLogDashboard() {
  return (
    <>
      <PageTitle />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SecurityEvents />
        </Grid>
      </Grid>
    </>
  );
}

export default SecurityLogDashboard;
