import { Box, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import PageCard from 'src/components/PageCard';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import LoadingIconButton from 'src/components/LoadingIconButton';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import { useCallback, useEffect, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { SecurityEvent, useGetSecurityLogQuery } from 'src/backend';
import { JSONTree } from 'react-json-tree';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LabeledBox from 'src/components/LabeledBox';
import { useLocation } from 'react-router-dom';
import SecurityEventsSearchBar from './SecurityEventsSearchBar';

dayjs.extend(utc);

export type DisplayEvent = {
  timestamp: string | undefined;
  event: string | undefined;
  serial: unknown;
  context: unknown;
};

function SecurityEvents() {
  const location = useLocation();
  const serialNumber = location.state?.serial ?? undefined;
  const groupName = location.state?.groupName ?? undefined;

  const columns: GridColDef<SecurityEvent>[] = [
    {
      field: 'timeStamp',
      headerName: 'Timestamp [UTC]',
      valueFormatter: (v) => dayjs.utc(v.value).format('MM-DD-YYYY HH:mm A'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'event',
      headerName: 'Event',
      sortable: false,
      flex: 1,
    },
    {
      field: 'serial',
      headerName: 'Serial Number',
      sortable: false,
      flex: 1,
    },
    {
      field: 'context',
      headerName: 'Client IP',
      valueFormatter: (v) => v.value.clientIp ?? '-',
      sortable: false,
      flex: 1,
    },
  ];
  const theme = {
    base00: 'white',
    base0D: 'black',
  };

  const [refetchRequired, setRefetchRequired] = useState(false);
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [serials, setSerials] = useState<string | undefined>(serialNumber);
  const [eventTypes, setEventTypes] = useState<string | undefined>(undefined);
  const [groupNames, setGroupNames] = useState<string | undefined>(groupName);

  const columnVisibilityModel: GridColumnVisibilityModel = {
    id: false,
  };

  const [selectedRow, setSelectedRow] = useState<DisplayEvent | undefined>(
    undefined,
  );

  const [rowCountState, setRowCountState] = useState({
    rowCount: 10,
    totalRowCount: -1,
    topPage: -1,
  });

  const [pageState, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetching, refetch } = useGetSecurityLogQuery({
    startDate,
    endDate,
    serials,
    eventTypes,
    groupNames,
    take: pageState.pageSize,
    page: pageState.page,
  });

  useEffect(() => {
    if (!isLoading && !isFetching && pageState.page >= rowCountState.topPage) {
      const previousPagesCount = pageState.page * pageState.pageSize;
      const currentPageCount = data?.data.length ?? 0;
      const nextPage = data?.data.length === pageState.pageSize ? 1 : 0;
      const rowCount = previousPagesCount + currentPageCount + nextPage;
      let totalRowCount = -1;
      if (currentPageCount < pageState.pageSize) {
        totalRowCount = previousPagesCount + currentPageCount;
      }

      setRowCountState({
        rowCount,
        totalRowCount: totalRowCount ?? -1,
        topPage: pageState.page,
      });
    }
  }, [
    rowCountState.topPage,
    isLoading,
    isFetching,
    pageState.page,
    pageState.pageSize,
    data?.data.length,
  ]);

  const resetPaging = useCallback(() => {
    setSelectedRow(undefined);
    setPaginationModel({
      page: 0,
      pageSize: 10,
    });
    setRowCountState({
      rowCount: 10,
      totalRowCount: rowCountState.totalRowCount,
      topPage: -1,
    });
  }, [rowCountState.totalRowCount]);

  const onPaginationModelChange = (m: GridPaginationModel) => {
    if (m.page !== pageState.page) {
      setSelectedRow(undefined);
      setPaginationModel({
        page: m.page,
        pageSize: pageState.pageSize,
      });
    }
  };

  const setFilteringOptions = useCallback(
    (
      afterDate: string | undefined,
      beforeDate: string | undefined,
      serialNumbers: string | undefined,
      groupNames: string | undefined,
      eventTypes: string | undefined,
    ) => {
      resetPaging();
      setStartDate(afterDate);
      setEndDate(beforeDate);
      setSerials(serialNumbers);
      setGroupNames(groupNames);
      setEventTypes(eventTypes);
    },
    [resetPaging],
  );

  const showEventContext = useCallback(
    (id: string) => {
      const event = data?.data.find((x) => x.id === id);
      const displayEvent: DisplayEvent = {
        timestamp: event?.timeStamp,
        event: event?.event,
        serial: event?.serial,
        context: event?.context,
      };
      setSelectedRow(displayEvent);
    },
    [data?.data, setSelectedRow],
  );

  useEffect(() => {
    if (refetchRequired) {
      setRefetchRequired(false);
      refetch();
    }
  }, [refetchRequired, refetch]);

  return (
    <PageCard
      title="Device Events"
      icon={<TableRowsOutlinedIcon />}
      contentSx={{ display: 'block' }}
      action={
        <LoadingIconButton
          loading={isLoading || isFetching}
          onClick={() => setRefetchRequired(true)}
        >
          <Tooltip title="Refresh">
            <CachedOutlinedIcon fontSize="large" />
          </Tooltip>
        </LoadingIconButton>
      }
    >
      <SecurityEventsSearchBar
        initialSerial={serialNumber}
        initialGroupName={groupName}
        setFilteringOptions={(
          afterDate,
          beforeDate,
          serialNumbers,
          groupNames,
          eventTypes,
        ) =>
          setFilteringOptions(
            afterDate,
            beforeDate,
            serialNumbers,
            groupNames,
            eventTypes,
          )
        }
      />
      <div style={{ display: 'flex' }}>
        <Grid item xs={6}>
          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 10 } },
            }}
            classes={{ row: 'selectable' }}
            columns={columns}
            rows={data?.data ?? []}
            columnVisibilityModel={columnVisibilityModel}
            loading={isLoading || isFetching}
            onRowClick={({ id }) => showEventContext(id.toString())}
            slotProps={{
              pagination: { count: rowCountState.totalRowCount },
            }}
            sx={{
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                display: 'none',
              },
              '& .MuiDataGrid-overlay': {
                height: '100%',
              },
              '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              '& .MuiTablePagination-displayedRows': {
                display: 'none',
              },
            }}
            paginationMode="server"
            filterMode="server"
            onPaginationModelChange={onPaginationModelChange}
            rowCount={rowCountState.rowCount}
            paginationModel={pageState}
            pageSizeOptions={[10]}
            hideFooterSelectedRowCount
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ padding: '30px 0 0 40px' }}>
            <LabeledBox
              text="Event details"
              backgroundColor="white"
              width="100%"
            >
              {selectedRow ? (
                <JSONTree data={selectedRow} theme={theme} hideRoot />
              ) : (
                'Select event to see details'
              )}
            </LabeledBox>
          </Box>
        </Grid>
      </div>
    </PageCard>
  );
}

export default SecurityEvents;
